.watch-movie {
//  position: relative; 
 min-height: 230px !important;
 max-height: 230px !important;
}

.unwatched-eye, .unwatched-trash {
  cursor: pointer;
}
#watched.overlay {
background-color: rgb(97, 97, 97);
position: relative;
width: 100%;
height: 100%;
opacity: 0.15;
-moz-opacity: 15%;
-webkit-opacity: 15%;
z-index: 1;
}

.card-m {
  margin: 6px;
}

.card {
  // margin: 25px;

  .card-footer{
    padding: 3px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}



@media only screen and (max-width: 600px) {

  // .watch-movie {
  //   //  position: relative; 
  //    max-height: 210px !important;
  //    max-width: 100px;
  //   }
    
}
