.list-wrapper {
  width: 75vw;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.doc-wrapper {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: center;

  h6 {
    color: white;
    margin: 3px;
  }
}
.progressh4 {
font-family: 'Kufam', cursive;
color: white;
margin-top: 2vh;
margin-bottom: 12px;
}

.green{ 
  color: #09fa5a
}

@media only screen and (max-width: 1100px) {
  .list-wrapper {
    width: 95vw;
  }
}

@media only screen and (max-width: 600px) {
  .give-up {
    display: none;
  }
  .pro-res {
    color: #ffa64f !important;
  }
}
