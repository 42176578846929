.carousel {
  width: 450px;
  margin: 0 auto;
}


@media only screen and (max-width: 600px) {
  .carousel {
    width: 350px;
    margin-top: 20px;

    img {
      width: 270px;
    }
  }
}
