.watch-link:hover {
  color: #09fa5a;
}

.staff-h4{
  font-family: 'Kufam', cursive;
  color: white;
  margin-top: 2vh;
  margin-bottom: 12px;
}

.popular-wrapper{
  width: 80vw;
  margin: 0 auto;
}
.pop-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.discover-heading {
  font-family: 'Kufam', cursive;
  color:white;
  text-align: center;
  margin-top: 25px;

  h2{
    margin-bottom: 0px !important;
  }

}

@media only screen and (max-width: 1100px) {
  .pick-img{
    width: 299px;
  }
}
