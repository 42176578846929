.posterimg {
 box-shadow: 0px 0px 1px #ddd, 0 0px 10px #ccc;
}

.flip-container {
	perspective: 1000px;
}
	
	.flip-container:hover .flipper, .flip-container.hover .flipper {
		transform: rotateY(180deg);
	}

.flip-container, .front, .back {
	width: 320px;
	height: 480px;
}

.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;

	position: relative;
}

.front, .back {
	backface-visibility: hidden;

	position: absolute;
	top: 0;
	left: 0;
}

.back {
  background-color:rgb(68, 68, 68);
  box-shadow: 0px 0px 1px #ddd, 0 0px 10px #ccc;
}

.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
}

.back {
	transform: rotateY(180deg);
}

.container {
  display: grid;
  height: 75vh;
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  border: 1px sold white;
}

.item-a{
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 8;
  place-self: center;
  align-self: start;
}

.item-b{
  grid-column-start: 2;
  grid-row-start: 8;
}

.item-c , .item-d, .item-e{
  color: white;
  grid-column-start: 1;
  place-self: start;
  align-self: start;
  font-weight: bold;
  margin-left: 95px;
}

.item-c{
  grid-row: 2/2;
  background-color: grey;
  border:1px solid lightgrey;
  padding: 8px;
  text-align: left;


}
.item-d{
  grid-row: 1/auto;
  text-align: left;
  h5 {
    color: #ffa64f;
    padding-bottom: 8px;
    }
}
.item-e{
  grid-row: 1/4;
}

.item-f {
  color:white;
  font-weight: bold;
  grid-column: 3/3;
  place-self: center;
  align-self: start;
  grid-row:1/auto;
}

.fa-check {
  color: #09fa5a;
}


.white {
  color: white;
}

blockquote {
  font-family: Georgia, serif;
  position: relative;
  color:white;
  margin: 0.5em;
  padding: 0.5em 2em .5em 2em;
}

blockquote:before {
  font-family: Georgia, serif;
  position: absolute;
  font-size: 4em;
  line-height: 1;
  top: 0;
  left: 0;
  color: #ffa64f;
  content: "\201C";
}
blockquote:after {
  font-family: Georgia, serif;
  position: absolute;
  float:right;
  font-size:4em;
  line-height: 1;
  right:0;
  bottom:0;
  color: #ffa64f;
  content: "\201D";
}
blockquote footer {
 text-align: center !important;
  margin-top: 10px;
  text-align:right;
}
blockquote cite:before {
  content: "\2013";
}


@media only screen and (max-width: 600px) {
  .container {
    display: grid;
    height: 64vh;
    .ov-res, .detail-char {
      display: none;
    }
    
    .item-a {
      grid-row-start: 2;
    }
    
    .flip-container, .flipper, .front, .back, .posterimg, .item-a {
        width: 240px !important;
        height: 360px !important;
      }
  
    .item-d {
      grid-row-start: 1;
      grid-column-start: 2 !important;
      margin-left: 0px;
      place-self: center;
      // margin-top: 34px;
      margin-bottom: 28px;
    }
  
    .blockquote-res {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 1100px) {

  .container {
    width: 100vw;
    img {
      width: 300px;
    }
    .item-d {
      margin-left: 0px;
    }
    }  
  }
