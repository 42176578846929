@import url('https://fonts.googleapis.com/css2?family=Kufam:wght@600&display=swap');
.nota-nav {
  font-family: 'Kufam', cursive;
}

.lnk {
  cursor: pointer;
  color: rgb(198, 198, 198) !important;
  text-decoration: none;

}

.lnk:hover {
  color: rgb(167, 167, 167) !important;
}

.orange {
  color: #ffa64f !important;
}

.greybg {
  background-color: rgb(62, 62, 62) !important;
}

@media only screen and (max-width: 600px) {
  .orange-bar{
    background-color:#ffa64f !important;
  }
  .lnk-right {
    margin-left: auto !important;
  }
  .nav-coll {
    background-color:rgb(42, 42, 42) !important;
    margin-top: 5px;
  }
  .navbar {
    padding: 8px 12px 8px 12px;
    background-color:rgb(42, 42, 42) !important;
  }
}
