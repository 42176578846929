.character-icons {
  margin: 10px 0px 20px 0px;
  img {
    margin: 5px;
    cursor: pointer;
    box-shadow: 1px 1px 2px darkgray;
  }
}



@media only screen and (max-width: 600px) {
  .character-icons {
    margin: 10px 0px 40px 0px;
    img {
      margin: 5px;
      cursor: pointer;
      box-shadow: 1px 1px 2px darkgray;
      width: 43px;
    }
  }

}
