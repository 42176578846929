.progress {
  width: 60%;
  margin: 10px auto 0px auto;
}

.moreProgress {
  background-color:#ffa64f !important;
  font-weight: bold;
}

.progressComplete {
  background-color:#09fa5a !important;
  font-weight: bold;
}


@media only screen and (max-width: 1100px) {
  .progress {
    width: 80vw;
  }
}
