@import url('https://fonts.googleapis.com/css2?family=Kufam:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.home-wrapper {
  h2 {
    font-family: 'Kufam', cursive;
    color: white;
    margin-top: 2vh;
    margin-bottom: 25px;
  }

  .lpText {
    width: 40vw;
    margin: 10px auto 0px auto;
    border-bottom: 1px solid rgb(79, 79, 79);

    p {
      color: white;
      font-size: 18px;
      font-family: 'Montserrat', sans-serif;
      margin-top: 25px;

      .blue {
        color: #ffa64f;
        font-weight: bold;
      }

      .cold {
        color: rgb(120, 184, 253);
        font-weight: bold;
      }
    }

    button {
      margin-bottom: 25px;
    }
  }
  .orangebg{
    background-color: #ffa64f;
  }

  .orangebg:hover {
    background-color: #db8f45
  }
}


@media only screen and (max-width: 600px) {
  .lp-header {
    display: none;
  }
  .lpText {
    text-align: center;
  }
}


@media only screen and (max-width: 1100px) {

  .lpText {
    width: 80vw !important;
    text-align: center;

    p {
      font-size: 17px !important;
      margin-top: 15px !important;
    }

    button {
      margin-bottom: 15px !important;
    }
  }
}
