.sim-show {
  img {
    width: 210px;
    margin: 10px 10px 4px 10px;
  }

  p {
    color: white;
  }

  .white{
    color:white
  } 
}
